import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { MenuItem, Popover } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Box from "@mui/material/Box";
import { Container } from "@mui/system";
import logo from "../../assets/images/Psycure Limited logo.png";
import { Button, useMediaQuery } from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import DrawerComp from "./Drawer";

import { ThemeProvider } from "@mui/styles";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { NavHashLink } from "react-router-hash-link";
import { useLocation } from "react-router-dom";
import "./Header.css";
import useAuth from "../../hooks/useAuth";
import { useState } from "react";
import { useEffect } from "react";

import { useLogout } from "../../hooks/useLogout";
import PersonIcon from "@mui/icons-material/Person";

import reviewMan from "../../assets/images/reviewMan.png";
import reviewFemale from "../../assets/images/reviewFemale.png";
import { servicesPageEvent } from "../../utils/FacebookPixelEvent";

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default function Header(props) {
  const [psycureUsers, setPsycureUsers] = useState([]);

  /* =====> AUTHENTICATION<======= */
  const { user } = useAuth();
  const { Logout } = useLogout();

  useEffect(() => {
    fetch(`https://api.thepsycure.com/users/${user?.email}`)
      .then((res) => res.json())
      .then((data) => {
        setPsycureUsers(data);
      });
  }, [user]);

  const location = useLocation();

  const [value, setValue] = React.useState();
  let theme = createTheme({
    typography: {
      fontFamily: ['"Poppins"'].join(","),
    },
  });
  theme = responsiveFontSizes(theme);
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = (e) => {
    Logout();
    setPsycureUsers([]);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // const userDetails = localStorage.getItem("userDetails") ? JSON.parse(localStorage.getItem('userDetails') || '{}') : { role: "", email: "" }

  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 900) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);

  // conditionally class define for navbar
  let classNameLogical;

  if (
    (location.pathname === "/" || location.pathname === "/home") &&
    !colorChange
  ) {
    classNameLogical = "ordinal";
  }
  if (
    location.pathname !== "/" ||
    (location.pathname !== "/home" && colorChange)
  ) {
    classNameLogical = "new";
  }
  // if(location.pathname === "/hoem" || location.pathname !== "/home" && colorChange )  {
  //     classNameLogical = "new";
  // }

  return (
    <React.Fragment style={{ overflowX: "hidden" }}>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <ElevationScroll {...props}>
          {/* <AppBar className={` ${(location.pathname === '/home' || location.pathname === '/' && location.pathname !== '/home/') && !colorChange ? "ordinal" : "new"}`}> */}
          <AppBar className={classNameLogical}>
            <Container style={{ maxWidth: "1400px" }}>
              <Toolbar
                sx={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "90%",
                  margin: "0 auto",
                }}
              >
                {isMatch ? (
                  <>
                    <Link to="/">
                      <img src={logo} alt="The Psycure logo" />
                    </Link>
                    <DrawerComp />
                  </>
                ) : (
                  <>
                    <Link to="/">
                      <img src={logo} alt="The Psycure logo" />
                    </Link>
                    <Box value={value} onChange={(e, value) => setValue(value)}>
                      <NavLink
                        to="/"
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <Button
                          // style={{ fontFamily: "Poppins" }}
                          className="menu-bar-primary-button"
                          color="inherit"
                        >
                          Home
                        </Button>
                      </NavLink>

                      {/* <NavLink
                        to="/about-us"
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <Button
                          // style={{ fontFamily: "Poppins" }}
                          className="menu-bar-primary-button"
                          color="inherit"
                        >
                          About
                        </Button>
                      </NavLink> */}

                      <NavHashLink
                        to="/#services"
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <Button
                          // style={{ fontFamily: "Poppins" }}
                          className="menu-bar-primary-button"
                          color="inherit"
                          onClick={() => servicesPageEvent()}
                        >
                          Services
                        </Button>
                      </NavHashLink>
                      {/* unwind mind */}
                      <NavLink
                        to="/unwind-mind-2024"
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <Button
                          // style={{ fontFamily: "Poppins" }}
                          className="menu-bar-primary-button"
                          color="inherit"
                        >
                          Unwind Mind
                        </Button>
                      </NavLink>
                      {/* summit 2024 */}
                      <NavLink
                        to="/all-psychologists"
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <Button
                          // style={{ fontFamily: "Poppins" }}
                          className="menu-bar-primary-button"
                          color="inherit"
                        >
                          Psychologists
                        </Button>
                      </NavLink>
                      {/*    <NavLink
                        to="/blog"
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <Button
                          // style={{ fontFamily: "Poppins" }}
                          className="menu-bar-primary-button"
                          color="inherit"
                        >
                          Blog
                        </Button>
                      </NavLink> */}

                      {/* <NavLink
                        to="/donation"
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <Button
                          // style={{ fontFamily: "Poppins" }}
                          className="menu-bar-primary-button"
                          color="inherit"
                        >
                          Donation
                        </Button>
                      </NavLink> */}
                      {/* 
                      <NavLink
                        to="/faq"
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <Button
                          // style={{ fontFamily: "Poppins" }}
                          className="menu-bar-primary-button"
                          color="inherit"
                        >
                          FAQ
                        </Button>
                      </NavLink> */}

                      <NavLink
                        to="/gift-card"
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <Button
                          // style={{ fontFamily: "Poppins" }}
                          className="menu-bar-primary-button"
                          color="inherit"
                        >
                          Gift Card
                        </Button>
                      </NavLink>
                      <NavLink
                        to="/fellowship"
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <Button
                          // style={{ fontFamily: "Poppins" }}
                          className="menu-bar-primary-button"
                          color="inherit"
                        >
                          Fellowship
                        </Button>
                      </NavLink>
                    </Box>
                    {user?.email ? (
                      <Box sx={{ color: "black" }}>
                        {
                          (psycureUsers?.role === "patient" &&
                            psycureUsers?.patientInfo?.gender == "female") ||
                          ((psycureUsers?.role === "psychologist" ||
                            psycureUsers?.role == "bloger") &&
                            psycureUsers?.gender == "female") ? (
                            <img
                              aria-describedby={id}
                              variant="contained"
                              onClick={handleClick}
                              src={reviewFemale}
                              alt=""
                              style={{
                                width: "3rem",
                                height: "3rem",
                                borderRadius: "100px",
                                objectFit: "cover",
                                cursor: "pointer",
                                pointerEvents: "visible",
                              }}
                            />
                          ) : (
                            <img
                              aria-describedby={id}
                              variant="contained"
                              onClick={handleClick}
                              src={reviewMan}
                              alt=""
                              style={{
                                width: "3rem",
                                height: "3rem",
                                borderRadius: "100px",
                                objectFit: "cover",
                                cursor: "pointer",

                                pointerEvents: "visible",
                              }}
                            />
                          )
                          // <AccountCircleIcon
                          //     fontSize="large"
                          //     onClick={handleClick} style={{ cursor: 'pointer', color: '#31C75A' }} />
                        }
                        <Popover
                          id={id}
                          open={open}
                          anchorEl={anchorEl}
                          onClick={handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                        >
                          <Box className="sub-menu">
                            <p style={{ textAlign: "center" }}>
                              {user?.displayName}
                            </p>

                            {psycureUsers?.role === "psychologist" ? (
                              <NavLink
                                to="/psychologist-dashboard"
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                  textAlign: "center",
                                }}
                              >
                                <Button
                                  style={{ fontFamily: "Poppins" }}
                                  color="inherit"
                                >
                                  Dashboard
                                </Button>
                              </NavLink>
                            ) : psycureUsers?.role === "admin" ? (
                              <NavLink
                                to="/admin-dashboard"
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                  textAlign: "center",
                                }}
                              >
                                <Button
                                  style={{ fontFamily: "Poppins" }}
                                  color="inherit"
                                >
                                  Dashboard
                                </Button>
                              </NavLink>
                            ) : psycureUsers?.role === "bloger" ? (
                              <NavLink
                                to="/bloger-dashboard"
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                  textAlign: "center",
                                }}
                              >
                                <Button
                                  style={{ fontFamily: "Poppins" }}
                                  color="inherit"
                                >
                                  Dashboard
                                </Button>
                              </NavLink>
                            ) : (
                              <NavLink
                                to="/Dashboard"
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                  textAlign: "center",
                                }}
                              >
                                <Button
                                  style={{ fontFamily: "Poppins" }}
                                  color="inherit"
                                >
                                  Dashboard
                                </Button>
                              </NavLink>
                            )}

                            <Button
                              style={{ fontFamily: "Poppins" }}
                              onClick={handleLogout}
                              color="inherit"
                            >
                              Logout
                            </Button>
                          </Box>
                        </Popover>
                      </Box>
                    ) : (
                      // <Dropdown />
                      <NavLink
                        to="/signin"
                        style={{
                          textDecoration: "none",
                          color: "black",
                          textAlign: "center",
                        }}
                      >
                        <MenuItem
                          onClick={handleClose}
                          style={{ fontSize: "0.875rem" }}
                          disableRipple
                        >
                          <PersonIcon style={{ marginRight: "1rem" }} /> LOGIN
                        </MenuItem>
                      </NavLink>
                    )}
                  </>
                )}
              </Toolbar>
            </Container>
          </AppBar>
        </ElevationScroll>
      </ThemeProvider>
    </React.Fragment>
  );
}
