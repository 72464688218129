import React, { useState } from "react";
import Experience from "./Experience/Experience";
import Awards from "./Awards/Awards";
import Services from "./Services/Services";
import Psychologist from "./Psychologist/Psychologist";

import Review from "./Review/Review";
import useAuth from "../../hooks/useAuth";
import Footer from "../../Shared/Footer/Footer";
import ChooseUs from "./ChooseUs/ChooseUs";
import Impact from "./Impact/Impact";

import "./Home.css";

import MessengerCustomerChat from "react-messenger-customer-chat";

// import PrivateRoute from '../Route/PrivateRoute/PrivateRoute';
import { useEffect } from "react";
import Header from "../../Shared/Header/Header";
import SectionOne from "./SectionOne/SectionOne";
import Divider from "./Divider/Divider";
import axios from "axios";

// import { createChannel, createClient, RtmMessage } from 'agora-rtm-react'
// import PrivateToSIgnup from '../Route/PrivateRoute/PrivateToSIgnup';

const Home = () => {
  const { user, authIsReady } = useAuth();
  const [showMessage, setShowMessage] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(false);
  let [patient, setPatient] = useState({});
  const [texts, setTexts] = useState([]);
  useEffect(() => {
    fetch(`https://api.thepsycure.com/users`)
      .then((res) => res.json())
      .then((data) => {
        let filteredPatient = data.filter(
          (indUser) => indUser?.email === user?.email
        );
        setPatient(filteredPatient[0]);
        if (filteredPatient[0]?.email) {
          localStorage.setItem("patientEmail", filteredPatient[0]?.email);
        }
      });
  }, [user]);
  /*   useEffect(() => {
    axios
      .get(`https://api.thepsycure.com/`)
      .then((data) => console.log(data.data))
      .catch((err) => console.log(err));
  }, [user]); */

  return (
    <div>
      <div style={{ position: "relative" }}>
        <Header />
        <SectionOne></SectionOne>
        {/* <Divider></Divider> */}
        {/* <ItemListing></ItemListing> */}
        {/* <Banner /> */}
        <Services />
        <Psychologist />
        <ChooseUs />

        <Impact />
        <Experience />

        <Awards />
        <Review />
        <Footer />
        {/* <div className="message_container" onClick={() => setShowMessage(!showMessage)}>
                    <ChatBubbleIcon />
                </div>

                {
                    showMessage && <Message email={patient?.email} patient={patient} setLoggedIn={setLoggedIn} isLoggedIn={isLoggedIn} texts={texts} setShowMessage={setShowMessage} setTexts={setTexts} />
                } */}

        <MessengerCustomerChat
          pageId="601028463692421"
          appId="1192971298280839"
          // pageId="102738385539129"
          // appId="836935557375921"
          themeColor="#31C75A"
          loggedInGreeting="Hi! How can we help you?"
          // loggedOutGreeting="Hi! How can we help you?"
        />
      </div>
    </div>
  );
};

export default Home;
