import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";

import { Container } from "@mui/system";
import { Link, NavLink, Outlet, useLocation } from "react-router-dom";
import BookingModal from "../EditUserInfo/BookingModal";

import logo from "../../assets/images/Psycure Limited logo.png";
import { useLogout } from "../../hooks/useLogout";

const drawerWidth = 240;

const normalStyle = {
  textDecoration: "none",
  color: "black",
  display: "flex",
};
const activeStyle = {
  textDecoration: "none",
  color: "black",
  display: "flex",
  fontWeight: "700",
};

function AdminDashboardNavigation(props) {
  const { Logout } = useLogout();
  const location = useLocation();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleLogout = (e) => {
    Logout();
  };

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Toolbar>
        <Link
          style={{ textDecoration: "none", color: "black", display: "flex" }}
          to="/"
        >
          {" "}
          <img src={logo} alt="" />
        </Link>
      </Toolbar>
      <Divider />
      <List>
        <ListItem>
          <NavLink
            style={
              location?.pathname == "/admin-dashboard"
                ? activeStyle
                : normalStyle
            }
            to="/admin-dashboard"
          >
            Dashboard
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink
            style={({ isActive }) => (isActive ? activeStyle : normalStyle)}
            to={`/admin-dashboard/users`}
          >
            Users
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink
            style={({ isActive }) => (isActive ? activeStyle : normalStyle)}
            to={`/admin-dashboard/psychologist`}
          >
            Psychologist
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink
            style={({ isActive }) => (isActive ? activeStyle : normalStyle)}
            to={`/admin-dashboard/payment-request`}
          >
            Payment Request
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink
            style={({ isActive }) => (isActive ? activeStyle : normalStyle)}
            to={`/admin-dashboard/promo-code`}
          >
            Add Promo
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink
            style={({ isActive }) => (isActive ? activeStyle : normalStyle)}
            to={`/admin-dashboard/chat-user`}
          >
            Add Chat User
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink
            style={({ isActive }) => (isActive ? activeStyle : normalStyle)}
            to={`/admin-dashboard/get-in-touch`}
          >
            Corporate servey
          </NavLink>
        </ListItem>
        {/* <ListItem>
                    <Link style={{ textDecoration: 'none', color: 'black' }} to={`/admin-dashboard/blog-category`}>Blog Category</Link>
                </ListItem> */}
        <ListItem>
          <NavLink
            style={({ isActive }) => (isActive ? activeStyle : normalStyle)}
            to={`/admin-dashboard/addBlog`}
          >
            Add Blog
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink
            style={({ isActive }) => (isActive ? activeStyle : normalStyle)}
            to={`/admin-dashboard/addWorkshop`}
          >
            Add Workshop
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink
            style={({ isActive }) => (isActive ? activeStyle : normalStyle)}
            to={`/admin-dashboard/registeredUser`}
          >
            Registered Workshop User
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink
            style={({ isActive }) => (isActive ? activeStyle : normalStyle)}
            to={`/admin-dashboard/workshop-promo-code`}
          >
            Add Workshop Promo
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink
            style={({ isActive }) => (isActive ? activeStyle : normalStyle)}
            to={`/admin-dashboard/gift-card-customers`}
          >
            Gift card customers
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink
            style={({ isActive }) => (isActive ? activeStyle : normalStyle)}
            to={`/admin-dashboard/chat`}
          >
            Assign chat
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink
            style={({ isActive }) => (isActive ? activeStyle : normalStyle)}
            to={`/admin-dashboard/fellowship`}
          >
            Fellowship
          </NavLink>
        </ListItem>
        <ListItem>
          <p
            sx={{ color: "black", display: "flex" }}
            onClick={handleLogout}
            style={{ display: "flex", margin: "0", cursor: "pointer" }}
          >
            <span>Logout</span>
          </p>
        </ListItem>
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        style={{ backgroundColor: "white", color: "black" }}
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          pb: 1.5,
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          {/* <h3>
                        <NavLink style={{ textDecoration: 'none', color: 'green' }} to='/'>Psycure Limited</NavLink>
                    </h3>
                    <DrawerComp /> */}
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        <>
          <Container sx={{ py: 10 }}>
            <Outlet />

            <Container sx={{ my: 20 }}>
              <BookingModal
                handleClose={handleClose}
                open={open}
              ></BookingModal>
            </Container>
          </Container>
        </>
      </Box>
    </Box>
  );
}

AdminDashboardNavigation.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default AdminDashboardNavigation;
