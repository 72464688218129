import React, { useState, useEffect } from "react";
import { ref, onValue, push, set, serverTimestamp } from "firebase/database";
import { realTimeDB } from "../../../firebase.config"; // Your Firebase configuration
import useAuth from "../../../hooks/useAuth";
import axios from "axios";

const PsychologistChat = () => {
  const [chats, setChats] = useState([]);
  const [psychologistId, setPsychologistId] = useState("");
  const [selectedChat, setSelectedChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [messageText, setMessageText] = useState("");
  const { user } = useAuth();
  console.log(user?.email);
  useEffect(() => {
    axios
      .get(`https://api.thepsycure.com/psychologists/${user?.email}`)
      .then((data) => {
        setPsychologistId(data.data?._id);
        // console.log(data.data);
      })
      .catch((err) => console.log(err));
  }, [user]);
  useEffect(() => {
    // Load chats assigned to this psychologist
    const chatsRef = ref(realTimeDB, "/chats");
    onValue(chatsRef, (snapshot) => {
      const data = snapshot.val();

      if (data) {
        // Filter only chats assigned to the current psychologist
        const assignedChats = Object.entries(data)
          .filter(([, chat]) => chat.psychologistId === psychologistId)
          .map(([id, chat]) => ({ id, ...chat }));

        setChats(assignedChats);
      } else {
        setChats([]); // No chats found
      }
    });
  }, [psychologistId]);

  useEffect(() => {
    // Load messages for the selected chat
    if (selectedChat) {
      const messagesRef = ref(realTimeDB, `/chats/${selectedChat.id}/messages`);
      onValue(messagesRef, (snapshot) => {
        const data = snapshot.val();
        const parsedMessages = data ? Object.values(data) : [];
        setMessages(parsedMessages);
      });
    }
  }, [selectedChat]);

  const sendMessage = () => {
    if (messageText.trim() && selectedChat) {
      const newMessageRef = push(
        ref(realTimeDB, `/chats/${selectedChat.id}/messages`)
      );
      set(newMessageRef, {
        text: messageText,
        timestamp: serverTimestamp(),
        sender: psychologistId,
        receiver: selectedChat.id.split("_")[0], // User ID
      });
      setMessageText("");
    }
  };

  return (
    <div style={{ display: "flex", padding: "20px", gap: "20px" }}>
      {/* Chat List */}
      <div>
        <h2>Assigned Chats</h2>
        <ul>
          {chats.map((chat) => (
            <li
              key={chat.id}
              onClick={() => setSelectedChat(chat)}
              style={{
                cursor: "pointer",
                fontWeight: selectedChat?.id === chat.id ? "bold" : "normal",
              }}
            >
              Chat with User: {chat.id.split("_")[0]}
            </li>
          ))}
        </ul>
      </div>

      {/* Chat Window */}
      <div style={{ flex: 1 }}>
        {selectedChat ? (
          <div>
            <h2>Chat with User: {selectedChat.id.split("_")[0]}</h2>
            <div
              style={{
                border: "1px solid #ccc",
                padding: "10px",
                height: "400px",
                overflowY: "scroll",
                marginBottom: "10px",
              }}
            >
              {messages.map((msg, index) => (
                <div key={index} style={{ marginBottom: "10px" }}>
                  <p>
                    <strong>
                      {msg.sender === psychologistId ? "You" : "User"}:
                    </strong>{" "}
                    {msg.text}
                  </p>
                  <small>{new Date(msg.timestamp).toLocaleString()}</small>
                </div>
              ))}
            </div>
            <textarea
              value={messageText}
              onChange={(e) => setMessageText(e.target.value)}
              placeholder="Type a message..."
              style={{
                width: "100%",
                padding: "10px",
                marginBottom: "10px",
              }}
            />
            <button onClick={sendMessage}>Send</button>
          </div>
        ) : (
          <p>Select a chat to view messages</p>
        )}
      </div>
    </div>
  );
};

export default PsychologistChat;
