import React, { useState, useEffect } from "react";
import {
  ref,
  onValue,
  push,
  set,
  update,
  serverTimestamp,
} from "firebase/database";
import { realTimeDB } from "../../firebase.config";
import axios from "axios";

const AdminChat = () => {
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [psychologists, setPsychologists] = useState([]);
  const [messages, setMessages] = useState([]);
  const [messageText, setMessageText] = useState("");
  const [unreadChats, setUnreadChats] = useState([]);
  const adminEmail = "psycureadmin@gmail.com";

  useEffect(() => {
    // Load all chats
    const chatsRef = ref(realTimeDB, "/chats");
    onValue(chatsRef, (snapshot) => {
      const data = snapshot.val();
      const parsedChats = data
        ? Object.entries(data).map(([id, chat]) => ({ id, ...chat }))
        : [];
      setChats(parsedChats);

      // Determine unread chats
      const unread = parsedChats.filter((chat) => chat.newMessage);
      setUnreadChats(unread.map((chat) => chat.id));
    });

    // Load psychologists (mock data or fetch from database)
    axios
      .get(`https://api.thepsycure.com/psychologists/`)
      .then((data) => setPsychologists(data.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    // Load messages for the selected chat
    if (selectedChat) {
      const messagesRef = ref(realTimeDB, `/chats/${selectedChat.id}/messages`);
      onValue(messagesRef, (snapshot) => {
        const data = snapshot.val();
        const parsedMessages = data ? Object.values(data) : [];
        setMessages(parsedMessages);

        // Mark chat as read
        const chatRef = ref(realTimeDB, `/chats/${selectedChat.id}`);
        update(chatRef, { newMessage: false });

        // Remove from unread list
        setUnreadChats((prev) =>
          prev.filter((chatId) => chatId !== selectedChat.id)
        );
      });
    }
  }, [selectedChat]);

  const sendMessage = () => {
    if (messageText.trim() && selectedChat) {
      const newMessageRef = push(
        ref(realTimeDB, `/chats/${selectedChat.id}/messages`)
      );
      set(newMessageRef, {
        text: messageText,
        timestamp: serverTimestamp(),
        sender: adminEmail,
        receiver: selectedChat.id.split("_")[0], // Send to user
      });
      setMessageText("");
    }
  };

  const assignPsychologist = (psychologistId) => {
    if (selectedChat) {
      const chatRef = ref(realTimeDB, `/chats/${selectedChat.id}`);
      update(chatRef, { psychologistId });
      alert("Psychologist assigned successfully.");
    }
  };

  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      await axios
        .get(`https://api.thepsycure.com/users/`)
        .then((data) => setUser(data.data))
        .catch((err) => console.log(err));
    };
    fetchUser();
  }, []);
  // console.log(unreadChats);
  return (
    <div>
      <h2>Admin Dashboard</h2>
      <div style={{ display: "flex", gap: "20px" }}>
        {/* Chats List */}
        <div>
          <h3>User Chats</h3>
          <ul>
            {chats.map((chat) => (
              <li
                key={chat.id}
                onClick={() => setSelectedChat(chat)}
                style={{
                  cursor: "pointer",
                  fontWeight: selectedChat?.id === chat.id ? "bold" : "normal",
                }}
              >
                {
                  user?.find((user) => user?._id === chat.id.split("_")[0])
                    ?.name
                }{" "}
                (Assigned:{" "}
                {psychologists?.find(
                  (psychologist) => psychologist?._id === chat.psychologistId
                )?.name || "Admin"}
                ){/* Unread message indicator */}
                {unreadChats.includes(chat.id) && (
                  <span
                    style={{
                      display: "inline-block",
                      marginLeft: "10px",
                      width: "10px",
                      height: "10px",
                      backgroundColor: "red",
                      borderRadius: "50%",
                    }}
                  ></span>
                )}
              </li>
            ))}
          </ul>
        </div>

        {/* Chat Window */}
        <div style={{ flex: 1 }}>
          {selectedChat ? (
            <div>
              <h3>
                Chat with{" "}
                {
                  user?.find(
                    (user) => user?._id === selectedChat.id.split("_")[0]
                  )?.name
                }
              </h3>
              <div
                style={{
                  border: "1px solid #ccc",
                  padding: "10px",
                  height: "300px",
                  overflowY: "scroll",
                  marginBottom: "10px",
                }}
              >
                {messages.map((msg, index) => (
                  <div key={index} style={{ marginBottom: "10px" }}>
                    <p>
                      <strong>
                        {msg.sender === adminEmail ? "You" : "User"}:
                      </strong>{" "}
                      {msg.text}
                    </p>
                    <small>{new Date(msg.timestamp).toLocaleString()}</small>
                  </div>
                ))}
              </div>
              <textarea
                value={messageText}
                onChange={(e) => setMessageText(e.target.value)}
                placeholder="Type a message..."
                style={{
                  width: "100%",
                  padding: "10px",
                  marginBottom: "10px",
                }}
              />
              <button onClick={sendMessage}>Send</button>
            </div>
          ) : (
            <p>Select a chat to view messages</p>
          )}
        </div>

        {/* Psychologist Assignment */}
        {selectedChat && (
          <div>
            <h3>Assign Psychologist</h3>
            <select
              onChange={(e) => assignPsychologist(e.target.value)}
              defaultValue=""
            >
              <option value="" disabled>
                Select Psychologist
              </option>
              {psychologists.map((psych) => (
                <option key={psych?._id} value={psych?._id}>
                  {psych.name}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminChat;
