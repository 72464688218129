import {
  Box,
  Container,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Slide,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});
const FellowCarousel = () => {
  const [open, setOpen] = React.useState(false);
  const [fellowDetails, setFellowDetails] = useState("");
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const fellows = [
    {
      name: "Fatema Islam",
      image:
        "https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/fellowship%2FFatema%20Islam.jpg?alt=media",
      details:
        "The power of words can heal, especially when they bridge cultural divides! Growing up in a Urdu speaking community of Old Dhaka, I saw firsthand how low literacy rates could silence important conversations about mental health. This sparked a fire in me to empower my community. Preserving and promoting my endangered mother tongue, Dhakaiya Urdu, became a personal mission. Now, I'm combining my advocacy skills with digital content creation to break down barriers and create a world where everyone feels supported in addressing mental health challenges.",
    },

    {
      name: "Ashish Paul",
      image:
        "https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/fellowship%2FAshish%20Paul.jpg?alt=media",
      details:
        "Ashish Paul graduated with an MBBS from Dhaka National Medical College in Bangladesh. He is dedicated to raising awareness about public health and mental health in his community. Ashish has initiated various social action projects to promote good health and well-being, with a particular focus on mental health. He believes in taking an innovative and collaborative approach to his projects. Ashish is committed to eradicating both communicable and non-communicable diseases in his community through these initiatives. Additionally, he has contributed to a youth-oriented, community-focused project aimed at addressing mental health challenges among young people.",
    },
    {
      name: "Fahmida Akter",
      image:
        "https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/fellowship%2FFahmida%20Akter.jpg?alt=media",
      details:
        "I’m Fahmida, a lived experience advocate and an early career public health researcher passionate about mental health. In last eight years I've provided crucial support, led several initiatives and founded my brainchild project 'Manosh'. I also advocated mental health on global platforms. Outside work, I'm into animal welfare, an ailurophile bookworm, and a stationery aficionado. I strive to blend compassion with every action, and now my goal is to create pathways for youth to understand their unique wellbeing needs and take decisions for better.",
    },
    {
      name: "Asifa Armin",
      image:
        "https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/fellowship%2FAsifa%20Armin.jpg?alt=media",
      details:
        "I completed my BBA and am currently pursuing a BSc in Psychology at King's College London. Passionate about mental health advocacy, I collaborate with various institutions to educate and support the younger generation. I believe nurturing mental health from an early age is crucial for their future growth and well-being.",
    },
    {
      name: "Sadik Hasan Abir",
      image:
        "https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/fellowship%2FSadik%20Hasan%20Abir.jpg?alt=media",
      details:
        "Student of Journalism,Media & Communication from Daffodil International University.Currently working as Research Associate in Centre for Social & Market Research.Aiming to work in NGO sector to connect people with their purpose,goal & ambition. ",
    },
    {
      name: "Afiya Jahin",
      image:
        "https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/fellowship%2FAfiya%20Jahin.jpg?alt=media",
      details:
        "I am currently a student of M.A. TESOL in University of Dhaka. Besides, I am working as an executive member in Psycure organisation. Also, I do photography. ",
    },
    {
      name: "Shahriar Hoque",
      image:
        "https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/fellowship%2FShahriar%20Hoque.jpg?alt=media",
      details:
        "I am currently enrolled at the University of Dhaka, majoring in Physics. Despite my STEM background I have always been curious about other fields of study and have tried to obtain introductory knowledge in those areas. Since joining university I have witnessed people facing difficult times and I have always wanted to help. Now, as I progress in my career I am also passionate about aiding individuals in overcoming mental health challenges.",
    },
    {
      name: "Ashif Iqbl",
      image:
        "https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/fellowship%2FAshif%20Iqbl.jpg?alt=media",
      details:
        "My name is Ashif Iqbal, hailing from Barishal City. Since 2016, I have been dedicated to volunteerism, driven by my passion for listening to and exploring people's stories. I am deeply concerned about climate change and its non-financial effects, particularly on mental health. Advocating for the rights of minority communities is a cause close to my heart. As a Psycure Youth Mental Health Fellow for 2024, I am excited to further my efforts in supporting mental health and creating a positive impact. Through my work, I strive to contribute to a more inclusive and compassionate society.",
    },
    {
      name: "Jahidul Islam Mishal",
      image:
        "https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/fellowship%2FJahidul%20Islam%20Mishal.jpg?alt=media",
      details:
        "Hello, this is Jahidul Islam Mishal, an undergrad student of psychology with a burning passion for making a positive impact on mental health. Along with my studies I have been and still am active in various clubs and organizations on and off the campus. I feel comfortable introducing myself as a mental health ambassador, learner and volunteer. At present, I'm working as a project associate in Bangladesh Youth Leadership. Alongside I'm doing the Aspire Leadership Programme organized by Harvard Business School's professors. My journey into the field of psychology has been driven by a deep desire to understand the complexities of the human mind and to contribute to the well-being of individuals and communities.",
    },
    {
      name: "Minhazul Ali Eshan",
      image:
        "https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/fellowship%2FMd%20Rohan%20Ali.jpg?alt=media",
      details:
        "Hi, My name is Minhazul Ali Eshan. I'm mental health and sustainable development advocate. I'm currently completing my bachelor on Psychology at the renownedUniversity of Dhaka in Bangladesh. Witnessing the devastating impact of mental health stigma and superstition surrounding social issues, I have taken the initiative to empower children and their families with knowledge, address mental health stigma & foster a more compassionate and supportive environment within my community. I strive to rebuild my society where everyone will have equal and adequate opportunities and freely express their concern and needs without hesitation.",
    },

    {
      name: "Nishat Zahan",
      image:
        "https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/fellowship%2FNishat%20Zahan.jpg?alt=media",
      details:
        "Nishat Zahan is currently pursuing her MBA at Mawlana Bhashani Science and Technology University. With a strong passion for creating mental health awareness and making a positive impact on the community, Nishat has dedicated herself to this cause.",
    },
    {
      name: "Sayada Nazila",
      image:
        "https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/fellowship%2FSayada%20Nazila.jpg?alt=media",
      details:
        "I am Sayada Nazila, studying Population and Public Health Sciences in East West University. As public health s have to study some courses about mental health,  social and behavioural communication courses. I get very much interested in it. I think for creating awareness and make changes in society, identifying people mental health and behaviour is very cruical. This fellowship helps us to make changes in community by different effective activities.",
    },
    {
      name: "Tahiya Islam",
      image:
        "https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/fellowship%2FTahiya%20Islam.jpg?alt=media",
      details:
        "Tahiya Islam is currently working as the Country Manager at HerWILL Bangladesh. HerWILL Bangladesh, a subsidiary of the esteemed HerWILL non-profit based in Atlanta, Georgia, USA, endeavors to provide STEM opportunities to men and women globally, thereby nurturing the tech leaders of tomorrow. Tahiya's leadership at HerWILL Bangladesh aligns with her commitment to achieving UN SDG5 (Gender Equality) by 2030, as she works to break gender biases, and barriers, and ensure inclusive space for everyone.  She is a certified advocate under the Global Schools Program of the United Nations Sustainable Development Solutions Network and is undergoing a climate change fellowship with Teach for All. She is working on developing a climate education and leadership curriculum for South East Asian Countries that highlights pressing climate crises and topics that today's generation needs to be aware of. She has more than 7 years of experience in running a non-profit organization, Aim Initiative Foundation, in Bangladesh and she has completed a teaching fellowship program with Teach for Bangladesh where she has worked with underprivileged children, and provided them with quality academic education, and healthcare awareness, and 21st-century leadership skills.",
    },
    {
      name: "Zannat Ara",
      image:
        "https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/fellowship%2FZannat%20Ara.jpg?alt=media",
      details:
        "Zannat Ara is a final year student of Psychology at Jagannath University, with four years of dedicated study in the field.Known for her excellent communication and linguistic skills. She has garnered many national and international awards,including being a Gold medalist and silver medalist in recitation and news casting at the 2nd International Language League.She was also honored as the Best Student of District at Creative Talent Hunt 2018, and so on. Zannat has actively participated in several mental health workshops.Driven by a deep-seated passion for mental health advocacy,she aims to help the individuals suffering from mental health issues.",
    },
    {
      name: "Zayed Bin Farid",
      image:
        "https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/fellowship%2FZayed%20Bin%20Farid.jpg?alt=media",
      details:
        "I am a genuine person. I am curious and eager to learn new ideas. My initial goal for life is to understand basic principles of a person's functioning. I believe in universal and simple language that can spread among the laymen easily and I am working for it. I love to being around the arts. Sometimes I writes, either it's poetry or psychological articals. Professionally, I am a psychologist. Though most of my energy burns around it but I like to be a simple human being around people.",
    },
    {
      name: "Nadia Islam Jannat",
      image:
        "https://firebasestorage.googleapis.com/v0/b/psycure-58e6d.appspot.com/o/fellowship%2FNadia%20Islam%20Jannat.jpg?alt=media",
      details:
        "I am Nadia Islam Jannat. I grew up by the bank of the river Jamuna, and people say I am as calm as the river, so friends call me “Nodi.” I run my unburnished curly hair through my fingers when the winds flow over the river, embracing my passion for an artistic view of life. From early adolescence, I discovered the three most important A's in me: Altruism, Art, and Affection. I won gold at the International Applied Physics Olympiad 2024 in Indonesia. I am interested in psychological books and love to explore art, psychology, biology, and applied physics.",
    },
  ];
  return (
    <div>
      <Container maxWidth="xl">
        <Typography
          style={{ textAlign: "center", margin: "4% 0" }}
          variant="h3"
        >
          Psycure Youth Mental Health Fellows 2024
        </Typography>
        <Grid
          container
          columns={{ xs: 1, md: 2, lg: 3, xl: 3 }}
          justifyContent="center"
          spacing={2}
          sx={{ padding: "0 5%" }}
        >
          {/* first 8 slide */}
          <Swiper
            // className="psychologist-container"
            // spaceBetween={30}
            grabCursor={false}
            // centeredSlides={true}
            slidesPerView={1}
            hashNavigation={{
              watchState: true,
            }}
            autoplay={{
              delay: 2200,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            breakpoints={{
              320: {
                // Small devices
                slidesPerView: 2,
                spaceBetween: 10,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 6,
                spaceBetween: 20,
              },
            }}
            navigation={true}
            modules={[Autoplay, Navigation]}
          >
            {fellows?.slice(0, 8).map((fellow) => (
              <SwiperSlide key={fellow?.name}>
                <Box
                  component="div"
                  onClick={() => {
                    handleClickOpen();
                    setFellowDetails(fellow?.details);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div>
                    <div>
                      <img src={fellow?.image} alt={fellow?.name} />
                    </div>

                    <div style={{ my: 5 }}>
                      <p>{fellow?.name}</p>
                    </div>
                  </div>
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Grid>
        {/* second 8 fellows */}
        <Grid
          container
          columns={{ xs: 1, md: 2, lg: 3, xl: 3 }}
          justifyContent="center"
          spacing={2}
          sx={{ padding: "0 5%" }}
        >
          {/* first 8 slide */}
          <Swiper
            // className="psychologist-container"
            // spaceBetween={30}
            grabCursor={false}
            // centeredSlides={true}
            slidesPerView={1}
            hashNavigation={{
              watchState: true,
            }}
            autoplay={{
              delay: 2200,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            breakpoints={{
              320: {
                // Small devices
                slidesPerView: 2,
                spaceBetween: 10,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 6,
                spaceBetween: 20,
              },
            }}
            navigation={true}
            modules={[Autoplay, Navigation]}
          >
            {fellows?.slice(8, 17).map((fellow) => (
              <SwiperSlide key={fellow?.name}>
                <Box
                  component="div"
                  onClick={() => {
                    handleClickOpen();
                    setFellowDetails(fellow?.details);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div>
                    <div>
                      <img src={fellow?.image} alt={fellow?.name} />
                    </div>

                    <div style={{ my: 5 }}>
                      <p>{fellow?.name}</p>
                    </div>
                  </div>
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Grid>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {fellowDetails}
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </Container>
    </div>
  );
};

export default FellowCarousel;
