import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Container, Grid, Paper } from "@mui/material";

import "swiper/css/navigation";
import ".././Home/Psychologist/Psychologist.css";

import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import verifiedImage from "../../assets/images/verified.png";
import Header from "../../Shared/Header/Header";

const AllPsychologist = () => {
  const [psychologists, setPsychologists] = useState([]);
  let [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    fetch("https://api.thepsycure.com/psychologists")
      .then((res) => res.json())
      .then((data) => {
        setDataLoaded(true);
        setPsychologists(data);
      });
  }, []);

  let approvedPsychologist = psychologists
    ?.filter((psychologist) => psychologist?.approved === true)
    .sort((a, b) => a.serial - b.serial);

  const values = approvedPsychologist?.map((psychologist) => {
    let totalReview, likedReview;
    totalReview = psychologist.reviews.length;
    likedReview = psychologist.reviews?.filter(
      (review) => review.rating === "like"
    ).length;

    return { totalReview, likedReview };
  });
  return (
    <Container sx={{ marginTop: { xs: 10 } }}>
      <Header />
      <div style={{ marginTop: "10%" }}>
        <h2 className="headin">Meet Our Psychologists</h2>
        <hr
          style={{
            display: "block",
            width: "10%",
            height: "3px",
            backgroundColor: "#31C75A",
            border: 0,
          }}
        />
      </div>

      {dataLoaded ? (
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 2, sm: 8, md: 12 }}
        >
          {approvedPsychologist?.map((psychologist, index) => (
            <Grid item xs={2} sm={4} md={4} key={index}>
              <div style={{ width: "100%" }}>
                <div
                  className="rectangle-59-all-psychologist"
                  style={{
                    height: "440px",
                    width: "100%",
                  }}
                >
                  <div
                    className="image-main"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      className="psychologist-image-square"
                      style={{
                        marginTop: "10px",
                        height: "151px",
                        width: "151px",
                        borderRadius: "75.5px",
                      }}
                    >
                      <img
                        style={{
                          borderRadius: "75.5px",
                          width: "100%",
                        }}
                        src={psychologist?.image}
                        alt=""
                        srcset=""
                      />
                      <img
                        className="verified-image"
                        src={verifiedImage}
                        style={{
                          height: "44px",
                          width: "44px",
                        }}
                        alt=""
                      />
                    </div>
                  </div>
                  <div>
                    <p className="psychologist-name">{psychologist?.name}</p>
                  </div>
                  <div
                    // className="review"
                    style={{
                      marginTop: "2px",
                      fontSize: "14px",
                      textAlign: "center",
                      marginBottom: "5%",
                    }}
                  >
                    <p style={{ margin: 0 }}>{psychologist?.degree}</p>
                    <p style={{ margin: 0, padding: 0, fontSize: 12 }}>
                      <span style={{ fontWeight: "bold" }}>
                        {psychologist?.experience}
                      </span>
                      <span> years of experience</span>
                      <span
                        style={{ display: "inline-block", margin: "0 5px" }}
                      >
                        ||
                      </span>
                      <span style={{ fontWeight: "bold" }}>
                        {psychologist?.totalSessions}
                      </span>
                      <span> sessions</span>
                    </p>
                  </div>

                  <div className="pricing">
                    <p className="pricing-text">{psychologist?.amount}৳</p>
                  </div>
                  <div className="details">
                    <div
                      className="details-text-home"
                      style={{ textAlign: "center" }}
                    >
                      <div>
                        <span>Specialized in </span>
                        {psychologist?.problems?.map((item, index) => (
                          <span>
                            {" "}
                            {item}
                            {index < psychologist?.problems?.length - 1
                              ? ","
                              : "."}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={`/psychologists/details/${psychologist?.name
                      ?.split(" ")
                      .join("-")}`}
                  >
                    <div className="booking-button-custom-home-parent">
                      <div className="booking-button-custom-home">
                        <p>Book Session</p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      ) : (
        <div style={{ textAlign: "center", marginTop: "1rem" }}>
          <CircularProgress />
        </div>
      )}
    </Container>
  );
};

export default AllPsychologist;
